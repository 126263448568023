@define-mixin grid $rowGap: var(--gutterWidth), $columnGap: var(--gutterWidth) {
    display: grid;
    gap: $rowGap $columnGap;
}

@define-mixin spanAllColumns {
    grid-column: 1 / -1;
}

@define-mixin columnsSpan $start, $span {
    grid-column: $start / span $span;
}

@define-mixin rowsSpan $start, $span {
    grid-row: $start / span $span;
}
