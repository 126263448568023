@define-mixin sectionHeading {
    @mixin h30;
    font-weight: 600;
    text-align: center;
    margin: 0;

    @media (--tablet) {
        @mixin h20;
    }
}

@define-mixin sectionTeaser {
    @mixin p30;
    @mixin hyphenateWords;
    text-align: center;

    @media (--tablet) {
        @mixin p20;
    }
}
