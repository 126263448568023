@define-mixin clearList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@define-mixin list {
    padding-left: 32px;
    margin: 16px 0;

    & li {
        position: relative;
        list-style-type: none;
        margin: 8px 0;

        &::before {
            content: '';
            width: 0.5em;
            height: 0.5em;
            display: block;
            background: var(--blackBaseLegacyColor);
            position: absolute;
            left: -1.5em;
            top: 0.5em;

            @media print {
                box-shadow: inset 0 0 0 0.5em var(--blackBaseLegacyColor);
            }
        }
    }
}
