.FactCard {
    background: var(--support02HKLighten20Color);
    padding: 24px;
}

.Heading {
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
}

.Icon {
    @mixin fixedRatioSvgIcon 24px;
    margin-right: 16px;
}

.Title {
    @mixin p30;
    @mixin hyphenateWords manual;
    font-weight: 600;
    margin: 0;

    @media (--tablet) {
        @mixin h50;
    }
}

.Content {
    @mixin hyphenateWords manual;
    @mixin p30;

    @media (--tablet) {
        @mixin p10;
    }
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
}
