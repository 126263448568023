.FactsSection {
    @mixin studyPageSectionPadding;
    background: var(--whiteBaseColor);
    color: var(--blackBaseColor);
}

.Title {
    @mixin sectionHeading;
    margin-bottom: 32px;

    @media (--tablet) {
        margin-bottom: 64px;
    }
}

.Facts {
    @mixin clearList;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (--desktop) {
        @mixin grid;
        grid-template-columns: repeat(3, 1fr);

        & .ListItem > * {
            height: 100%;
        }
    }

    &.Facts--withSeparator {
        padding-bottom: 24px;
        border-bottom: 2px solid var(--blackLighten40Color);
        margin-bottom: 24px;

        @media (--tablet) {
            padding-bottom: 32px;
            margin-bottom: 32px;
        }
    }
}

.Tags {
    @mixin clearList;
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
}

.TagInfo {
    @mixin p40;
    font-weight: 600;
}
